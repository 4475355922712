var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-vertical relative"},[_c('icon',{staticClass:"bg-net",attrs:{"icon":"CircleNet"}}),_c('commons-header',{attrs:{"help":false},on:{"restart":function () {}}}),_c('h2',{staticClass:"teko-subtitle"},[_vm._v("Inputs Received by cadCAD!")]),_c('p',{staticClass:"font-ibm fs-18 text-center"},[_vm._v(" Will the RadicalxChange Commons succeed, the Movement take off, and new economy creation cascade around the world? ")]),_c('div',{staticClass:"layout-form"},[_c('commons-navigation',{staticStyle:{"grid-area":"nav"}}),_c('flex',{staticClass:"text-center flex-center",staticStyle:{"grid-area":"field"}},[_c('grid',{staticClass:"sim-data"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The founding members of the RadicalxChange Commons',
            placement: 'top',
          }),expression:"{\n            content: 'The founding members of the RadicalxChange Commons',\n            placement: 'top',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.foundingMembers))]),_c('p',[_vm._v("HATCHERS")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The number of project proposals initially collected before the Hatchers begin their voting',
            placement: 'top',
          }),expression:"{\n            content: 'The number of project proposals initially collected before the Hatchers begin their voting',\n            placement: 'top',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.proposals))]),_c('p',[_vm._v("INITIAL PROPOSALS")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The percentage of total initial funds delegated to the funding pool to fund approved RadicalxChange initiatives',
            placement: 'top',
          }),expression:"{\n            content: 'The percentage of total initial funds delegated to the funding pool to fund approved RadicalxChange initiatives',\n            placement: 'top',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.funding)+"%")]),_c('p',[_vm._v("COMMONS TRIBUTE")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The maximum percentage of the funds within the Funding Pool that can be withdrawn at any one time',
            placement: 'bottom',
          }),expression:"{\n            content: 'The maximum percentage of the funds within the Funding Pool that can be withdrawn at any one time',\n            placement: 'bottom',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.votingPower)+"%")]),_c('p',[_vm._v("MAX SPENDING")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The amount of time it takes for an individual’s voting power to reach 80% of its full power',
            placement: 'bottom',
          }),expression:"{\n            content: 'The amount of time it takes for an individual’s voting power to reach 80% of its full power',\n            placement: 'bottom',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.decisions)+" Days")]),_c('p',[_vm._v("VOTING SPEED")])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'The percentage of funds that feeds back into the Funding Pool when members choose to sell their RxC tokens',
            placement: 'bottom',
          }),expression:"{\n            content: 'The percentage of funds that feeds back into the Funding Pool when members choose to sell their RxC tokens',\n            placement: 'bottom',\n          }"}]},[_c('p',[_vm._v(_vm._s(_vm.form.exiting)+"%")]),_c('p',[_vm._v("EXIT TRIBUTE")])])])],1)],1),_c('button',{attrs:{"commons":""},on:{"click":function($event){return _vm.$router.push('/submit')}}},[_vm._v("run simulation")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }